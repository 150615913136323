var paperBanlistIndex = 0 //看到第几版
var epaper //报纸全版信息
var paperWidth = 450
var paperHeight = 640
var windowWidth = window.innerWidth;
var ratio = windowWidth / paperWidth
var year,month,day,loading
var lockDays = []
var domain = 'https://api.51peoplephoto.com/api'

function useIndex(){
    localStorage.getItem('user')
    //获取所有出版日期
    $.post(domain + '/rmsy/medium/paper/getPaperDateList',(res)=>{
        if (res.status){
            lockDays = res.result
        }
        let date = getQueryParam('date')
        if (date === null){
            getEpaper(lockDays[lockDays.length])
        }else{
            getEpaper(date)
        }

    })



    $('.thumbnail-btn').click(function() {
        $('#thumbnail').toggle();
    });

    $('.introduction-btn').click(function() {
        $('#introduction').toggle();
    });

    $('.paper-date-btn').click(function() {
        $('#paper-date').toggle();
    });



    // createMonth(year, month, day, lockDays)
    $('#preMonth').click(function() {
        if (month-1 === 0){
            year--
            month = 12
        } else {
            month--
        }
        createMonth(year, month, day, lockDays)
    });
    $('#preYear').click(function() {
        year--
        createMonth(year, month, day, lockDays)
    });
    $('#nextMonth').click(function() {
        if (month+1 === 13){
            year++
            month = 1
        } else {
            month++
        }
        createMonth(year, month, day, lockDays)
    });
    $('#nextYear').click(function() {
        year++
        createMonth(year, month, day, lockDays)
    });

    $('#paper-date').toggle();


}
//获取当日的报纸信息
function getEpaper(date){
    console.log(date)
    $.ajax({
        url: domain + '/rmsy/medium/paper/getPager',
        type: 'POST', // 或者 'POST'
        contentType: 'application/json', // 设置请求头的 Content-Type
        dataType: 'json', // 期望服务器返回的数据类型
        data: JSON.stringify({"date":date}), // 将 JavaScript 对象转换为 JSON 字符串
        success: (res)=> {
            // 请求成功后的回调函数
            console.log(',',res);
            epaper = res.result
            $('#epaper-date').text(epaper.publishdate)
            store.set('epaper', epaper)
            let lastDate = new Date(epaper.publishdate)
            year = lastDate.getFullYear()
            month = lastDate.getMonth() + 1
            day = lastDate.getDate()
            createMonth(year, month, day, lockDays)
            modifyEpaperStatus()


            //渲染报纸刊物
            $('#banList').text('')
            let folder = epaper.folder
            for(let i in epaper.banList){
                const ban = epaper.banList[i]
                //添加版面
                $('#banList').append(`
                    <div>
                        <img class="paper" id="pageimg_${i}" src="${folder}/${ban.perverimgurl}" alt="${ban.vername}" usemap="#Map_${i}"/>
                        <map name="Map_${i}" id="Map_${i}"></map>
                    </div>
                `)
                //添加缩略图
                $('#thumbnail-list').append(`
                    <div class="thumbnail-item" onclick="see(${i})">
                        <img class="thumbnail-paper" src="${folder}/${ban.thumbpic}" alt="${ban.vername}">
                        <div class="thumbnail-text">${ban.verorder}-${ban.vername}</div>
                    </div>
                `)
                //添加新闻目录
                $('#introduction-items').append(`
                    <div class="ban">
                    <div class="ban-title">${ban.verorder} ${ban.vername}</div>
                        <ul id="ban-${i}">
                        </ul>
                    </div>
                `)


                //获取img的高和宽
                const img = $(`#pageimg_${i}`)[0]

                let imgWidth = img.width
                let imgHeight = img.height


                if(imgWidth === 0){
                    imgWidth = window.innerWidth || document.documentElement.clientWidth
                }
                if(imgHeight === 0){
                    const pageHeight = window.innerHeight || document.documentElement.clientHeight
                    imgHeight = pageHeight * 533/pageHeight
                }

                //添加Map
                const articleList = ban.articleList
                for (let s in articleList){
                    let article = articleList[s]
                    let coords = coordsCompute(article.coordinate, imgWidth, imgHeight )
                    // console.log(article.title,article.coordinate, coords)
                    if ( article !== undefined) {
                        // console.log(article.coordinate)
                        $(`#Map_${i}`).append(`
                        <area coords="${coords}" alt="${article.title}" isvalue="0"
                          class="Map_${i}" coordinate="${article.coordinate}" shape="poly"
                          href="javascript:show(${i}, ${s})" data-count="1">
                        `)
                    }
                    //为新闻目录添加新闻
                    $(`#ban-${i}`).append(`
                        <li><a href="/news.html?articleId=${article.id}">${article.title}</a></li>
                    `)
                }
            }
            $(`#introduction-items`).append(`
                <div style="height: 100px"></div>
            `)
            const rpage = store.get('rpage') //后退来的，暂时未用数据

            let indexPage = store.get('indexPage')

// alert(indexPage)
            $('.flipbook').turn({
                acceleration: true,
                width: windowWidth,
                height: parseInt(ratio * paperHeight),
                elevation: 50,
                gradients: true,
                autoCenter: true,
                display: "single",
                page:indexPage
            })
            $(".flipbook").bind("turning", function(event, page, view) {
                paperBanlistIndex = page - 1
                modifyEpaperStatus()
            });

            //加载第一个报纸图片后打开隐藏层
            $('#pageimg_0').on('load', function() {
                $('#loader-masker').hide()
            });
        },
        error: function(xhr, status, error) {
            // 请求失败后的回调函数
            console.error("Error: " + error);
        }
    });
}
//看第几版
function see(banIndex){
    $(".flipbook").turn("page",banIndex + 1)
    $('#thumbnail').toggle();
}
//计算坐标轴
function coordsCompute(coords, width, height){
    const arr = coords.split(',')
    if (arr.length > 4) { //比例算法
        return [
            arr[0]*width,
            arr[1]*height,
            arr[2]*width,
            arr[3]*height,
            arr[4]*width,
            arr[5]*height,
            arr[6]*width,
            arr[7]*height
        ]
    } else { //加载区间算法

    }
}
// 跳转新闻页
function show(banIndex, articleIndex, news ){
    window.location.href = (`./news.html?articleId=${epaper.banList[banIndex].articleList[articleIndex].id}`)
    // alert(epaper.banList[banIndex].articleList[articleIndex].title)
}
//更改页面信息
function modifyEpaperStatus(){
    let indexPage = parseInt(epaper.banList[paperBanlistIndex].verorder)
    if (indexPage !== 1){
        store.set('indexPage', parseInt(epaper.banList[paperBanlistIndex].verorder))
    }
    $('#paper-verorder').text(epaper.banList[paperBanlistIndex].verorder)
    $('#paper-vername').text(epaper.banList[paperBanlistIndex].vername)
}
//获取get传值
function getQueryParam(name) {
    let search = window.location.search.substring(1); // 去掉 '?'
    let params = search.split('&');
    for (let i = 0; i < params.length; i++) {
        let param = params[i].split('=');
        if (param[0] === name) {
            return param[1];
        }
    }
    return null;
}
//选择日期
function selectDay(dateValue){
    // const dateValue = $(this).data('date');
    // console.log('dateValue', dateValue)
    // $('#paper-date').toggle()
    // getEpaper(dateValue)
    window.location.href = ('./index.html?date=' + dateValue)
    store.set('indexPage', 1)
    // loading = weui.loading('加载中', {
    //     className: 'custom-classname'
    // });
    // setTimeout(function () {
    //     loading.hide(function() {
    //         console.log('`loading` has been hidden');
    //     });
    // }, 3000);
}

//创建日期
function createMonth (year, month, day, lockDays){
    $("#paper-date-month").text('')
    $("#paper-date-today").text(`${year}-${makeUpDate(month)}-${makeUpDate(day)}`)
    const monthLastDay = getDaysInMonth(year, month)
    const monthFistDayWeek = getMonthFirstDayWeek(year, month)

    const now = new Date();
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDay = now.getDate();

    //确定每月第一天的位置
    for (let i = 1; i <= monthFistDayWeek ; i ++){
        $("#paper-date-month").append(`<div><span></span></div>`);
    }
    //添加日期
    for (let i = 1; i <= monthLastDay ; i ++){
        let lock = `${year.toString()}-${makeUpDate(month)}-${makeUpDate(i)}`
        if(todayYear === year && todayMonth === month && todayDay === i){
            $("#paper-date-month").append(`<div onclick="selectDay('${lock}')"  class="date-day active" data-date="${lock}"><span>${makeUpDate(i)}</span></div>`);
        }else if (lockDays.includes(lock)){
            $("#paper-date-month").append(`<div onclick="selectDay('${lock}')" class="date-day lock" data-date="${lock}"><span>${makeUpDate(i)}</span></div>`);
        }else{
            $("#paper-date-month").append(`<div onclick="selectDay('${lock}')" class="date-day" data-date="${lock}"><span>${makeUpDate(i)}</span></div>`);
        }
    }
}
//补充月日前面的0
function makeUpDate(str){
    str = parseInt(str);
    return str < 10 ? '0' + str : str;
}


//计算本月第一天是星期几
function getMonthFirstDayWeek(year, month) {
    const firstDayOfNextMonth = new Date(year, month-1, 1);
    return firstDayOfNextMonth.getDay()
}
//获取当月多少天
function getDaysInMonth(year, month) {
    const firstDayOfNextMonth = new Date(year, month, 1);
    const oneDay = 1000 * 60 * 60 * 24;
    const yesterday = new Date(firstDayOfNextMonth.getTime() - oneDay);
    return yesterday.getDate()
}

//获取新闻信息
var news
function useNews(){
    $('#back-icon').click(function() {
        // window.location.href = '/index.html'
        // const indexUrl = store.get('indexUrl')
        // window.location.href = indexUrl
        store.set('rpage',1)
        history.go(-1)
    });
    const articleId = getQueryParam('articleId')

    var user, userCode

    if (localStorage.getItem('user') !== 'null') {
        user = store.get('user')
        userCode = user.userCode
    } else {
        userCode = '007'
    }

    var loading = weui.loading('loading', {
        className: 'custom-classname'
    });

    $.ajax({
        url: domain + '/rmsy/article/getArticle',
        type: 'POST', // 或者 'POST'
        contentType: 'application/json', // 设置请求头的 Content-Type
        dataType: 'json', // 期望服务器返回的数据类型
        data: JSON.stringify({
            "articleId":articleId,
            "userCode":userCode
        }), // 将 JavaScript 对象转换为 JSON 字符串
        success: (res)=> {
            loading.hide()
            news = res.result
            $('#title').text(news.title)
            $('#title1').text(news.title1)
            $('#title2').text(news.title2)
            $('#content').html(news.content)
            if(news.isComplete){
                $('#buy-more').hide()
            }else{
                $('#buy-more').show()
            }
        }
    })
}

//微信分享
function shareWx(){
    const fullUrl = window.location.href;
    $.ajax({
        url: domain + '/rmsy/wx/getJsSDKSign?url=' + fullUrl,
        type: 'POST', // 或者 'POST'
        contentType: 'application/json', // 设置请求头的 Content-Type
        dataType: 'json', // 期望服务器返回的数据类型
        data: JSON.stringify({
            "url":fullUrl
        }), // 将 JavaScript 对象转换为 JSON 字符串
        success: (res)=> {
            console.log(res)
            const rs = res.result
            wx.config({
                debug: false,
                appId: "wx0691f0e343f40975", // 必填，公众号的唯一标识
                timestamp: rs.timestamp, // 必填，生成签名的时间戳
                nonceStr: rs.nonceStr, // 必填，生成签名的随机串
                signature: rs.signature, // 必填，签名
                jsApiList: [
                    'onMenuShareAppMessage',
                    'onMenuShareTimeline',
                    'chooseWXPay',
                    'showOptionMenu',
                    "updateAppMessageShareData",
                    "hideMenuItems",
                    "showMenuItems",
                    "onMenuShareTimeline",
                    'onMenuShareAppMessage'
                ], // 必填，需要使用的JS接口列表，图片预览接口
            })
            wx.ready(() => {
                //updateAppMessageShareData 新方法 ，现在不支持
                wx.onMenuShareAppMessage({
                    title: '人民摄影手机数字报',
                    desc: '人民摄影', // 分享描述
                    link: 'https://m.51peoplephoto.com/',
                    imgUrl: 'https://m.51peoplephoto.com/assets/images/logo.png', // 分享图标
                    success: function () {
                    }
                })
            })
            wx.error((res) => {
            })
        }
    })
}

//根据token换取用户信息
function getUserForToken(url) {
    const urlParams = new URLSearchParams(new URL(url).search)
    const token =  urlParams.get('token')
    const postUrl =  'https://api.51peoplephoto.com/api/auth/loginByToken'
    $.ajax({
        url: postUrl,
        type: 'POST', // 或者 'POST'
        contentType: 'application/json', // 设置请求头的 Content-Type
        dataType: 'json', // 期望服务器返回的数据类型
        data: JSON.stringify({
            "token":token
        }), // 将 JavaScript 对象转换为 JSON 字符串
        success: (res)=> {
            store.set('user', res.result)
            console.log('res', res)
        }
    })
}